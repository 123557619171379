import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useStyles } from 'styles';
import { MainNavigationButtonProps } from 'types';

const DEFAULT_HEIGHT_WIDTH = 45;

export const MainNavigationButton: React.FC<MainNavigationButtonProps> = ({
  linkTo,
  imgSrc,
  delay,
  title,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <motion.div
      initial={{ bottom: -200, opacity: 0 }}
      animate={{ top: 0, opacity: 1 }}
      transition={{
        duration: 0.8,
        delay: delay,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      onClick={() => {
        navigate(linkTo);
      }}
      className={classes.mainNavButton}
    >
      <motion.div
        whileTap={{ scale: 0.9 }}
        className={`text-uppercase text-center ${classes.mainNavButtonInner}`}
      >
        <img
          src={imgSrc}
          width={DEFAULT_HEIGHT_WIDTH}
          height={DEFAULT_HEIGHT_WIDTH}
          className={`mb-2 ${classes.mainNavButtonImg}`}
          alt={title}
        />
        <span>{title}</span>
      </motion.div>
    </motion.div>
  );
};
