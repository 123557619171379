import { MainNavigationButton } from 'components/MainNavigationButton';
import { Nav, Navbar } from 'react-bootstrap';
import { useStyles } from 'styles';
import { buttonDataType } from 'types';

export const MainNavigation = () => {
  const classes = useStyles();

  const BUTTONS_DATA: buttonDataType[] = [
    {
      linkTo: '/location',
      delay: 0.5,
      imgSrc: '/image/navigation/location.svg',
      title: 'Location',
    },
    {
      linkTo: '/book',
      delay: 0.75,
      imgSrc: '/image/navigation/book.svg',
      title: 'Book',
    },
    {
      linkTo: '/materials',
      delay: 1,
      imgSrc: '/image/navigation/materiali.svg',
      title: 'Materiali',
    },
    {
      linkTo: '/keyplan-page',
      delay: 1.25,
      imgSrc: '/image/navigation/keyplan.svg',
      title: 'Keyplan',
    },
    {
      linkTo: '/style',
      delay: 1.5,
      imgSrc: '/image/navigation/style.svg',
      title: 'Style',
    },
    {
      linkTo: '/map',
      delay: 1.75,
      imgSrc: '/image/navigation/mappa.svg',
      title: 'Mappa',
    },
    {
      linkTo: '/partner',
      delay: 2,
      imgSrc: '/image/navigation/partner.svg',
      title: 'Partner',
    },
  ];

  return (
    <footer
      className={`position-absolute bottom-0  mt-auto w-100 p-5 ${classes.footer}`}
    >
      <Navbar className={`justify-content-center ${classes.nav}`}>
        <Nav>
          {BUTTONS_DATA.map((button, id) => {
            return (
              <MainNavigationButton
                linkTo={button.linkTo}
                delay={button.delay}
                imgSrc={button.imgSrc}
                title={button.title}
                key={id}
              />
            );
          })}
        </Nav>
      </Navbar>
    </footer>
  );
};
