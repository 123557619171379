import { Flipbook } from 'components/Flipbook';
import { HomeButton } from 'components/HomeButton';
import React, { Fragment } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useStyles } from 'styles';
import { BasicPageProps } from 'types';
import { MotionDiv } from 'utils/MotionDiv';

const BOOK_IMAGES = [
  '/image/book/book-1.jpg',
  '/image/book/book-2.jpg',
  '/image/book/book-3.jpg',
  '/image/book/book-4.jpg',
  '/image/book/book-5.jpg',
  '/image/book/book-6.jpg',
  '/image/book/book-7.jpg',
  '/image/book/book-8.jpg',
  '/image/book/book-9.jpg',
  '/image/book/book-10.jpg',
  '/image/book/book-11.jpg',
  '/image/book/book-12.jpg',
  '/image/book/book-13.jpg',
  '/image/book/book-14.jpg',
  '/image/book/book-15.jpg',
  '/image/book/book-16.jpg',
];
const DEFAULT_WIDTH = 830;
const DEFAULT_HEIGHT = 520;

export const Book: React.FC<BasicPageProps> = ({ backgroundImage }) => {
  const classes = useStyles();

  return (
    <MotionDiv>
      <Fragment>
        <Container fluid className="mt-auto h-100">
          <Row className="h-100 overflow-hidden">
            <Flipbook
              width={DEFAULT_WIDTH}
              height={DEFAULT_HEIGHT}
              images={BOOK_IMAGES}
            />
          </Row>
        </Container>

        <HomeButton />

        <div className={classes.background}>
          <img
            src={backgroundImage}
            alt="Book"
            className={classes.backgroundObject}
          />
        </div>
      </Fragment>
    </MotionDiv>
  );
};
