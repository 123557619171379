import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useStyles } from 'styles';

const HOME_ICON = '/image/backicon.svg';

export const HomeButton: React.FC = () => {
  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <motion.div
      initial={{ bottom: -200, left: 80, opacity: 0 }}
      animate={{ bottom: 60, left: 80, opacity: 1 }}
      transition={{
        duration: 0.8,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      className={classes.homeButton}
    >
      <motion.div whileTap={{ scale: 0.9 }}>
        <div
          onClick={() => {
            navigate('/');
          }}
        >
          <img
            src={HOME_ICON}
            alt="Home icon"
            className={classes.homeIconImg}
          />
        </div>
      </motion.div>
    </motion.div>
  );
};
